'use client';

import { CSSProperties, useState } from 'react';
import { pushProductClicked } from '@theme/utils/gtm';
import useFavButton from '../../hooks/use-fav-button';
import { Image } from '@akinon/next/components/image';
import { Price, Link, Icon } from '@theme/components';
import { IProduct } from '@theme/types';
import { Product } from '@akinon/next/types';
import { twMerge } from 'tailwind-merge';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import clsx from 'clsx';

// register Swiper custom elements
register();

interface Props {
  product: IProduct;
  width: number;
  height: number;
  index: number;
  className?: string;
  textColor?: string;
  favouriteIconColor?: string;
  favouriteIconColorActive?: string;
  favouriteIconColorHover?: string;
  favouriteIconColorHoverFill?: string;
  basketOfferTextColor?: string;
  basketOfferBackgroundColor?: string;
  discountBadgeTextColor?: string;
  discountBadgeBackgroundColor?: string;
  productNameClassName?: string;
  removeLazyFromFirstImage?: boolean;
}

export const ProductItem = (props: Props) => {
  // TODO: Static image will change (TR)
  const { product, width, height, index, className, removeLazyFromFirstImage } =
    props;
  const [isMouseHover, setIsMouseHover] = useState(false);
  const { FavButton } = useFavButton(Number(product?.pk), isMouseHover);
  const productImages = product?.productimage_set;
  const absolute_url = product?.absolute_url;
  const product_name = product?.name;
  const retail_price = product?.retail_price;
  const price = product?.price;
  const basketOffers = product?.basket_offers;
  const textColor = props?.textColor;
  const variants = product?.extra_data?.variants;
  const badge_gorsel = product?.attributes?.badge_gorsel;

  const renderBasketOffers = () => {
    const labels = basketOffers?.filter((offer) => offer.label);

    if (labels?.length > 0) {
      return (
        <div>
          {labels.map((offer) => {
            return (
              <p
                key={offer.label}
                className="text-[var(--basket-offer-text-color)] bg-[var(--basket-offer-background-color)]"
              >
                {offer.label}
              </p>
            );
          })}

          {Number(retail_price) > Number(price) && (
            <Price
              value={parseFloat(retail_price)}
              customStyle="text-squant text-xs line-through font-normal"
            />
          )}
        </div>
      );
    } else if (Number(retail_price) > Number(price)) {
      return (
        <div className="flex mb-2">
          <span className="text-sm font-sans text-[var(--discount-badge-text-color)] bg-[var(--discount-badge-background-color)] px-[0.125rem] mr-2">
            -%
            {Math.round(
              100 - (parseInt(String(price)) / parseInt(retail_price)) * 100
            )}
          </span>
          <Price
            value={parseFloat(retail_price)}
            customStyle="text-squant text-sm line-through font-normal"
          />
        </div>
      );
    }
  };

  const renderProductImages = () => {
    return productImages?.map((item, imageIndex) => {
      return (
        <>
          {badge_gorsel && imageIndex === 1 && (
            <Image
              src={'/badge-gorsel.png'}
              alt="Badge"
              width={30}
              height={30}
              imageClassName="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px]"
              className="absolute top-1 left-1 lg:top-2 lg:left-2"
            />
          )}
          <swiper-slide key={item.pk}>
            <Image
              src={item.image}
              alt={product_name}
              aspectRatio={width / height}
              width={width}
              height={height}
              crop="center"
              className="w-full h-full"
              imageClassName="w-full object-cover block"
              {...(index <= 3 && imageIndex === 0 && removeLazyFromFirstImage
                ? { loading: 'eager' }
                : { loading: 'lazy' })}
            />
          </swiper-slide>
        </>
      );
    });
  };

  const renderProductVariants = (className: string) => {
    let colorVariant = variants?.filter(
      (variant) => variant.attribute_key === 'ent_ColorDescription'
    );

    colorVariant = colorVariant?.[0];

    if (!colorVariant) return null;

    // check if there is other variant option than the current product
    const isMoreThanOneVariant = colorVariant.options.filter(
      (option) => option.product?.pk !== product?.pk
    );

    if (!isMoreThanOneVariant?.length) return null;

    return (
      <div
        className={twMerge(
          'flex gap-x-2 lg:justify-center items-center py-[.375rem] border-t border-t-steam lg:-mt-12 w-full lg:absolute lg:bg-white z-10',
          className
        )}
      >
        {colorVariant?.options?.map((option, index) => {
          if (index > 3) return null;
          const variantProduct = option.product;
          return (
            <Link
              key={variantProduct?.pk}
              href={product?.absolute_url}
              className={clsx(
                'rounded-full border-cerebral-grey border-[.0313rem] lg:w-9 lg:h-9 w-6 h-6',
                product?.pk == variantProduct?.pk && '!border-black'
              )}
            >
              <Image
                src={variantProduct?.productimage_set[0]?.image}
                alt={product?.name}
                width={36}
                height={36}
                className="w-full h-full"
                imageClassName="h-full w-full rounded-full"
              />
            </Link>
          );
        })}
        {colorVariant?.options?.length > 4 && (
          <span className="flex items-center justify-center text-xs rounded-full border-cerebral-grey border-[.0313rem] bg-mes-white h-9 w-9">
            +{colorVariant?.options?.length - 4}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={twMerge(
        'group relative text-sm text-left flex flex-col justify-start w-full lg:p-4 lg:border lg:border-transparent lg:hover:border lg:hover:border-cerebral-grey',
        className
      )}
      data-testid="product-box"
      onMouseEnter={() => {
        window?.innerWidth >= 1024 && setIsMouseHover(true);
      }}
      onMouseLeave={() => {
        window?.innerWidth >= 1024 && setIsMouseHover(false);
      }}
      style={
        {
          '--basket-offer-text-color': props?.basketOfferTextColor || '#5d0024',
          '--basket-offer-background-color':
            props?.basketOfferBackgroundColor || '#ffffff',
          '--discount-badge-text-color':
            props?.discountBadgeTextColor || '#ffffff',
          '--discount-badge-background-color':
            props?.discountBadgeBackgroundColor || '#5d0024'
        } as CSSProperties
      }
    >
      <div className="relative w-full cursor-pointer mb-[.4375rem]">
        {/** TODO Data hazır olunca burayı dinamikleştir */}
        {/* <p className="hidden lg:block uppercase text-xs -rotate-90 absolute top-[40px] -left-4 z-[2] opacity-0 lg:group-hover:opacity-100 transition-opacity duration-200">
          HAND MADE
        </p> */}
        <Link
          href={absolute_url}
          onClick={() => pushProductClicked(product as unknown as Product)}
          className="w-full block"
        >
          {productImages?.length > 0 ? (
            <swiper-container
              slides-per-view="auto"
              loop="true"
              auto-height="true"
              navigation="true"
              navigation-next-el={`#product-item-next-${product?.pk}`}
              navigation-prev-el={`#product-item-prev-${product?.pk}`}
            >
              {renderProductImages()}
            </swiper-container>
          ) : (
            <Image
              className="w-full"
              src="/noimage.jpg"
              aspectRatio={width / height}
              width={width}
              height={height}
              alt={product_name}
              imageClassName="object-cover w-full"
            />
          )}
        </Link>
        <button
          id={`product-item-prev-${product?.pk}`}
          className="absolute hidden lg:block z-10 top-1/2 -translate-y-1/2 left-1 disabled:opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        >
          <Icon name="chevron-start" className={twMerge('text-sm')} />
        </button>
        <button
          id={`product-item-next-${product?.pk}`}
          className="absolute hidden lg:block z-10 top-1/2 -translate-y-1/2 right-1 disabled:opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        >
          <Icon name="chevron-end" className={twMerge('text-sm')} />
        </button>
        {isMouseHover && renderProductVariants('hidden lg:flex')}
      </div>
      <div className="w-full">
        <div className="flex justify-between w-full mb-3">
          <Link
            href={absolute_url}
            data-testid={`${product_name}-${index}`}
            onClick={() => pushProductClicked(product as unknown as Product)}
            style={{ color: textColor }}
            className={twMerge(
              'pr-4 line-clamp-2',
              props?.productNameClassName
            )}
          >
            {product_name}
          </Link>
          <FavButton
            iconColor={props?.favouriteIconColor}
            iconColorActive={props?.favouriteIconColorActive}
            iconColorHover={props?.favouriteIconColorHover}
            iconColorHoverFill={props?.favouriteIconColorHoverFill}
          />
        </div>
        <div className="w-full flex flex-col">
          {renderBasketOffers()}
          <Price
            value={price}
            data-testid="product-price"
            style={{ color: textColor }}
            customStyle="lg:text-xl text-base leading-[1.5938rem]"
          />
        </div>
        {renderProductVariants('lg:hidden border-t-0 pt-3')}
      </div>
    </div>
  );
};
